<template>
  <v-card v-if="nbIdentities" class="mt-3">
    <v-card-title class="pa-0">
      <v-subheader
        >Unregistered Device{{ nbIdentities > 1 ? 's' : '' }}</v-subheader
      >
    </v-card-title>
    <v-card-text class="pt-0">
      <v-alert
        outline
        :value="true"
        class="mb-3 py-2 px-3"
        type="info"
        color="white"
      >
        Click on a found sensor below to add it to an installation
      </v-alert>
      <v-btn
        color="primary"
        outline
        small
        v-for="(identity, serial) in filteredIdentities"
        :key="`btn-identity-${serial}`"
        @click="quickAddSensor(identity, serial)"
        class="sensorIdentity ml-1"
      >
        <p>{{ nameForIdentity(identity) }}</p>
        <p>{{ serial }}</p>
      </v-btn>
    </v-card-text>
  </v-card>
</template>
<script>
import { mapState, mapGetters, mapActions } from 'vuex'

export default {
  name: 'IdentitiesWidget',
  computed: {
    ...mapGetters('sensors', ['serialsInActiveSite', 'allSensorsInSentry']),
    ...mapGetters('sentries', ['sentriesInSite']),
    ...mapState('sensors', ['identities', 'identityTypeMapping']),
    ...mapState('sites', ['activeSiteId']),
    ...mapGetters('cameras', ['allCamerasInSentry', 'camerasSet']),
    ...mapGetters('rf_sensors', ['allRfSensorsInSentry', 'allDsxSensorsInSentry', 'rfSensorsSet', 'dsxSensorsSet']),
    allSensorsPerSentry(sentry) {
      const sensors = this.allSensorsInSentry(sentry) || []
      sensors.cameras = this.allCamerasInSentry(sentry.id)
      sensors.rf_sensors = this.allRfSensorsInSentry(sentry.id)
      sensors.dsx_sensors = this.allDsxSensorsInSentry(sentry.id)

      return sensors
    },
    shouldDisplayUnlinkedSensors() {
      // Return true if there is any empty installations for this site
      const sentries = this.sentriesInSite(this.activeSiteId)
      return sentries.some(
        s => Object.values(this.allSensorsPerSentry(s)).flat().length === 0
      )
    },
    allSensorsSerialNumbersInSite() {
      const cameras = Object.values(this.camerasSet).map(c => c.serial_number)
      const rfSensors = Object.values(this.rfSensorsSet).map(c => c.serial_number)
      const dsxSensors = Object.values(this.dsxSensorsSet).map(c => c.serial_number)

      return [...this.serialsInActiveSite, ...cameras, ...rfSensors, ...dsxSensors]
    },
    filteredIdentities() {
      // Filters out:
      //  - identities for sensors that have already been added to the site
      //  - identities with unknown site (0) unless it's RF (no SmartHub) or there is an empty installation
      return Object.fromEntries(
        Object.entries(this.identities).filter(
          ([serial, { site_id, type }]) =>
            !this.allSensorsSerialNumbersInSite.includes(serial) &&
            ((site_id == 0 &&
              (type === 'rftwo' || type === 'rf_patrol' || this.shouldDisplayUnlinkedSensors)) ||
              site_id == this.activeSiteId)
        )
      )
    },
    nbIdentities() {
      return Object.values(this.filteredIdentities).length
    }
  },
  methods: {
    ...mapActions('sensors', ['CLEAR_IDENTITIES']),
    quickAddSensor(identity, serial) {
      const { group, type, model } = this.identityTypeMapping(identity) || {}
      this.$store.commit('setActiveGroup', { [group]: true })
      return this.$router.push({
        name: 'sensors.new',
        params: { sensorType: type, serial, model }
      })
    },
    nameForIdentity(identity) {
      return this.identityTypeMapping(identity).name
    }
  },
  watch: {
    activeSiteId() {
      this.CLEAR_IDENTITIES()
    }
  }
}
</script>
<style scoped>
.sensorIdentity {
  text-transform: initial;
  height: 42px;
  animation: fadeIn 0.5s normal 0s, shake 0.5s ease 3s;
}
.sensorIdentity::v-deep .v-btn__content {
  display: block;
  line-height: 0px;
}
.sensorIdentity p:nth-child(1) {
  opacity: 1;
  font-weight: bold;
}
.sensorIdentity p:nth-child(2) {
  font-weight: 300;
  margin-bottom: 0px;
}
@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes shake {
  0% {
    transform: translate(1px, 1px) rotate(0deg);
  }
  10% {
    transform: translate(-1px, -2px) rotate(-1deg);
  }
  20% {
    transform: translate(-3px, 0px) rotate(1deg);
  }
  30% {
    transform: translate(3px, 2px) rotate(0deg);
  }
  40% {
    transform: translate(1px, -1px) rotate(1deg);
  }
  50% {
    transform: translate(-1px, 2px) rotate(-1deg);
  }
  60% {
    transform: translate(-3px, 1px) rotate(0deg);
  }
  70% {
    transform: translate(3px, 1px) rotate(-1deg);
  }
  80% {
    transform: translate(-1px, -1px) rotate(1deg);
  }
  90% {
    transform: translate(1px, 2px) rotate(0deg);
  }
  100% {
    transform: translate(1px, -2px) rotate(-1deg);
  }
}
</style>
